<template>
    <div>
        <section class="ftco-section">
            <div class="container" >
                <div class="row justify-content-center pb-3">
                    <div class="col-md-12 heading-section text-center ftco-animated">                        
                        <h2 class="mb-2">Member Only</h2>
                    </div>
                </div>

                <div class="login-dialog" v-if="UMember == null">
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-6">
                            <label>MemberNo</label>
                            <b-input v-model="MemberData.MBCode" placeholder="รหัสสมาชิก" ></b-input>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>

                    <div class="row mt-1">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-6">
                            <label>Church</label>
                            <select class="form-control" v-model="MemberData.ChurchID">
                                <option v-for="(op, index) in optChurch" :key="index"
                                    v-bind:value="op.value"
                                >
                                    {{op.text}}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-6 text-center">
                            <b-button variant="success" @click="SignIn()">Sign In</b-button>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-6">
                            <b-button variant="link" @click="MemberCheckShow = true">ตรวจสอบการเป็นสมาชิก? (Member Check)</b-button>
                            <div v-if="MemberCheckShow">
                                <div class="row mt-2">
                                    <div class="col-sm-12">
                                        <h4 class="text-center">Member Check</h4>
                                        <label>Name</label>
                                        <b-input v-model="MemberSearch.Name" placeholder="ขื่อ"></b-input>
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Surname</label>
                                        <b-input v-model="MemberSearch.Surname" placeholder="นามสกุล"></b-input>
                                    </div>
                                    <div class="col-sm-12">
                                        <p></p>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 text-center">
                                            <b-button block variant="info"  @click="MemberCheck()">Check</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>
                </div>

                <div v-else>
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-6">
                            <b-card>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <img class="card-logo" :src="require('../assets/images/card-img.png')">
                                    </div>
                                    <div class="col-sm-8">
                                        Code: {{UMember.mbcode}}<br/>
                                        Name: {{UMember.title}} {{UMember.name}} {{UMember.surname}} <br/>
                                        Church: {{ChurchIDToChurch(UMember.church_id)}}<br/>
                                        Expire Date: {{ExpformatDate(UMember.expdate)}}
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-sm-12 text-center">
                                        <b-button variant="success">Member Content</b-button>
                                    </div>
                                </div>
                            </b-card>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>
                </div>

                <div v-if="ShowMemberContent" >
                    <div class="row justify-content-center pb-3 mt-4">
                        <div class="col-md-12 heading-section text-center ftco-animated">                        
                            <h2 class="mb-2">Member Content</h2>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <ChoirMinistryListComp v-bind:HideLockConent="false"></ChoirMinistryListComp>
                        </div>
                    </div>
                </div>                
            </div>
        </section>        
    </div>
</template>

<script>
import SiteDataServ from "../services/SiteDataService.vue"
import ChoirMinistryListComp from "../components/ChoirMinistryListComp.vue";

export default {
    components:{
        ChoirMinistryListComp
    },
    data(){
        return{
            MemberData:{
                MBCode:"",
                ChurchID:null
            },
            optChurch:[
                { value:null, text: "--Select Church--" }
            ],
            UMember:null,
            ShowMemberContent: false,
            MemberCheckShow: false,
            MemberSearch:{
                Name:"",
                Surname:""
            }
        }
    },
    methods:{
        GetChurchList(){
            let firstOpt = { value:null, text: "--Select Church--" };
            this.optChurch = [];
            this.optChurch.push(firstOpt);
            SiteDataServ.ChurchGets()
                .then(res => {
                    let FDs = res.data;
                    let opt = this.ListToOpt(FDs, "church_id", "churchname", firstOpt);
                    this.optChurch = opt;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        ListToOpt(IList, FieldValue, FieldText, firstOpt){
            let result = [];
            if(firstOpt != null)
                result.push(firstOpt);

            for(let i = 0; i < IList.length; i++){
                let IT = IList[i];
                let opt = {
                    value: (FieldValue == null? IT : IT[FieldValue]),
                    text: IT[FieldText]
                };
                result.push(opt);
            }
            return result;
        },
        SignIn(){
            if(this.MemberData.MBCode == "" || this.MemberData.ChurchID == null){
                this.$swal({
                        title:'Member Sign In',
                        icon:'question',
                        html: 'Please insert <b class="text-danger">MemberCode</b> and <b class="text-danger">Church</b> '
                    });
                return;
            }

            SiteDataServ.MemberSignIn(this.MemberData.MBCode, this.MemberData.ChurchID)
                .then(res => {
                    this.UMember = res.data;
                    this.ShowMemberContent = true;
                    console.log(this.UMember);
                })
                .catch(err => {
                    this.UMember = null;
                    console.log(err);
                    this.$swal({
                        title:'Member Sign In',
                        icon:'error',
                        html: 'Your and not member or invalid MemberCode'
                    });
                })
        },
        ExpformatDate(_ExpDate){
            let Temp = new Date(_ExpDate);
            
            let year = Temp.getFullYear();
            let month = ('0' + (Temp.getMonth() + 1)).slice(-2);
            let date = ('0' + Temp.getDate()).slice(-2);
            
            let result = date+"/"+month+"/"+year;
            return result;
        },
        ChurchIDToChurch(church_id){
            let result = "";
            if(church_id != null && this.optChurch.length > 0){
                let ch = this.optChurch.filter(f => f.value == church_id);
                if(ch != null && ch.length > 0)
                    result = ch[0].text;
            }
            return result;
        },
        MemberCheck(){
            SiteDataServ.CheckMember(this.MemberSearch.Name, this.MemberSearch.Surname)
                .then(res => {
                    console.log(res.data);
                    if(res.data == null || res.data == ''){
                        this.$swal({
                            title:'Member Check',
                            icon:'error',
                            html: 'Your and not member!!!'
                        });
                    }
                    else{
                        let mb = res.data;
                        this.$swal({
                            title:'Member Check',
                            icon:'success',
                            html: mb.title + ' ' + mb.name + ' ' + mb.surname + '<br/> Code:'+mb.mbcode +'<br/> ExpDate:'+mb.expdate
                        });
                    }
                })
                .catch(err => {
                    // this.UMember = null;
                    console.log(err);
                    this.$swal({
                        title:'Member Check',
                        icon:'error',
                        html: 'Your and not member!!!'
                    });
                })
        }
    },
    mounted(){
        this.GetChurchList();
    }
}
</script>

<style scoped>
.card-logo{
    width: 100%;
    height: auto;
}
</style>